.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
    max-width: 100% !important;
}

.modal-main {
    width: 100%;
    height: auto;
    display:flex;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.container_modal{
    background: #fff;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    height: auto;
    margin: 0 auto;
    padding: 10px 15px 20px 15px!important;
    margin-bottom: 35px;
    /* max-width: 50%; */
}
main{
    width: 100%;
    height: 100%;
    padding: 5%;
    margin:0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1365px;
}

