/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 8, 2020 */
@font-face {
    font-family: 'montserratbold';
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratlight';
    src: url('montserrat-light-webfont.woff2') format('woff2'),
         url('montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratregular';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('opensans-bold-webfont.woff2') format('woff2'),
         url('opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanslight';
    src: url('opensans-light-webfont.woff2') format('woff2'),
         url('opensans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* Roboto */
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

/* Roboto Slab */
@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
